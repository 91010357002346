<script setup>
import { useNuxtApp, useRouter } from 'nuxt/app';
import { useAuthenticationStore } from '~/stores/account/authentication.store';
import helper from '~/resources/js/libraries/helper';

const authStore = useAuthenticationStore();
const router = useRouter();
const localePath = useLocalePath()
const { $toast } = useNuxtApp()

const form = ref({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
});

const passwordVisible = ref(false)

const register = async () =>  {
    await authStore.register(form.value)
        .then((res) => {
            helper.localStorage.setData('vartur-email', form.value.email)
            router.push({ path: localePath({ name: 'account.verify' }) });
        })
        .catch((err) => {
            const defaultMessage = 'Something Went Wrong Please Try Again'
            $toast.addToast({ title: err.message || defaultMessage, color: "red", position: 'top-10' })  
        })
};

const showPassword = async () => {
    passwordVisible.value = !passwordVisible.value;
}
</script>


<template>
    <div class="relative">
        <header class="sticky top-0 z-10">
            <div class="px-4 h-20 bg-indigo-900 flex items-center justify-between text-white relative">
                <NuxtLink :to="localePath({ name: 'account.login' })"> 
                    <i class="fa-solid fa-chevron-left fa-xl"></i> 
                </NuxtLink>
            </div>
        </header>
        <main class="mb-32 px-4">
            <div class="flex flex-col justify-center my-10 w-full text-center items-center">
                <img :src="`images/${appId}/logo-blue.webp`" alt="" class="w-44">
                <p class="mt-3 text-gray-500 text-xl">{{ $t('client.create_account') }}</p>
            </div>
            <form class="*:mb-3">
                <div>
                    <input 
                        v-model="form.first_name"
                        type="text" 
                        placeholder="First Name"
                        class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
                    />
                </div>
                <div>
                    <input 
                        v-model="form.last_name"
                        type="text" 
                        placeholder="Last Name"
                        class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
                    />
                </div>
                <div>
                    <input 
                        v-model="form.email"
                        type="email" 
                        placeholder="Email"
                        class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
                    />
                </div>
                <div class="relative">
                    <input 
                        id="register-password"
                        v-model="form.password"
                        :type="passwordVisible ? 'text' : 'password'"
                        placeholder="Password"
                        class=" block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
                    />
                    <div @click="showPassword" class="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer">
                        <i :class="passwordVisible ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'" class="text-gray-500"></i>
                    </div>
                </div>
                <div @click="register" class="mt-16 w-full flex">
                    <a class="bg-indigo-900 text-white rounded-lg p-3 capitalize w-full text-center">
                        {{ $t('client.create_account') }}
                    </a>
                </div>
                <div class="mt-40 flex justify-center items-center ">
                    <span class="w-full m-auto text-center text-gray-600">{{ $t('client.by_signing_up_you_can_agree_to') }} <a href="#"><span class="text-indigo-900 font-medium"> {{ $t('client.terms_conditions') }} </span></a> {{ $t('client.and') }} <a href="#"><span class="text-indigo-900 font-medium"> {{ $t('client.privacy_policy') }} </span> </a></span>
                </div>
            </form>
        </main>
    </div>
</template>